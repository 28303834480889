<sc-layout>
  <div class="content">
    <div class="credentials" *ngIf="!sent">
      <p class="instructions"><strong>Enter your email address</strong></p>
      <p>We'll send an email you can use to reset your password.</p>
      <form>
        <div class="form-group">
          <input type="email" class="form-control" placeholder="Email Address" name="email" [(ngModel)]="email" />
        </div>

        <div class="invalid" *ngIf="invalid">
          <p>Invalid email</p>
        </div>
        <div class="actions row">
          <button type="button" class="btn btn-light btn-sm col" [routerLink]="AppRoute.Login">Cancel</button>
          <button
            type="submit"
            class="btn btn-primary btn-sm col"
            [disabled]="loading"
            [ngClass]="{ 'btn-loading': loading }"
            (click)="sendEmail()"
          >
            Send Email
          </button>
        </div>
      </form>
    </div>
    <div class="credentials" *ngIf="sent">
      <p class="instructions">The email has been sent</p>
      <p>Check your inbox for instructions on resetting your password.</p>
      <form class="d-grid">
        <button type="submit" class="btn btn-primary btn-sm" [routerLink]="AppRoute.Login">Return to Login</button>
      </form>
    </div>
  </div>
</sc-layout>
