<sc-layout>
  <div class="content">
    <div class="credentials">
      <p class="instructions">Log in to ScentConnect<br />Manufacturing Portal</p>
      <form class="d-grid">
        <div class="form-group">
          <input type="email" class="form-control" placeholder="Email Address" name="email" [(ngModel)]="email" />
        </div>

        <div class="form-group">
          <input type="password" class="form-control" placeholder="Password" name="password" [(ngModel)]="password" />
          <a class="forgot-password" [routerLink]="AppRoute.ForgotPassword" routerLinkActive="active">
            Forgot Password?</a
          >
        </div>

        <div class="invalid" *ngIf="invalid">
          <p>Invalid email or password</p>
        </div>

        <button type="submit" class="btn btn-primary btn-sm" [scBtnProcessing]="loading" (click)="login()">
          Log in
        </button>
      </form>
    </div>
  </div>
</sc-layout>
