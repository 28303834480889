/* src/app/auth/auth.scss */
.content {
  display: inline-block;
  max-width: 24rem;
  width: 100%;
  text-align: center;
}
.credentials {
  background-color: #e9ecef;
  color: #6c757d;
  text-align: center;
  padding: 1.25rem;
  border-radius: 0.25rem;
  margin: 0.875rem 0.625rem;
}
.credentials p.instructions {
  font-weight: 700;
}
.credentials p.instructions + p {
  margin-top: -10px;
}
.credentials .invalid {
  background-color: #fef7f5;
  color: #e84e25;
  border: 0.0625rem solid #f29981;
  border-radius: 0.25rem;
  margin-bottom: 1rem;
}
.credentials .invalid p {
  margin: 0.125rem;
}
.actions {
  display: flex;
  margin: 0 -0.125rem;
}
.actions > * {
  margin: 0 0.125rem;
}
/*# sourceMappingURL=auth.css.map */
