import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { authenticationGuard, loginGuard } from '@core';

import { ForgotPasswordComponent, LoginComponent } from './auth';

const routes: Routes = [
  {
    path: 'login',
    component: LoginComponent,
    canActivate: [loginGuard],
  },
  {
    path: 'forgot-password',
    component: ForgotPasswordComponent,
  },
  {
    path: '',
    loadChildren: () => import('./portal/portal.module').then((m) => m.PortalModule),
    canMatch: [authenticationGuard],
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
