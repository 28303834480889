/* src/app/auth/login/login.component.scss */
.forgot-password {
  display: block;
  margin: 0.25rem 0.125rem;
  text-align: right;
  font-size: 0.875rem;
}
.address {
  color: #fff;
}
.address p {
  margin: 0;
}
.terms {
  margin: 1rem 0 0.125rem;
}
.terms p {
  display: inline-block;
  margin: 0 1rem;
}
a {
  color: #343a40;
}
/*# sourceMappingURL=login.component.css.map */
