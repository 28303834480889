import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { SharedModule } from '@shared';

import { ForgotPasswordComponent } from './forgot-password';
import { LayoutComponent } from './layout';
import { LoginComponent } from './login';

@NgModule({
  imports: [CommonModule, FormsModule, NgbModule, RouterModule, SharedModule],
  declarations: [LayoutComponent, LoginComponent, ForgotPasswordComponent],
  providers: [],
  exports: [],
})
export class AuthModule {}
