/* src/app/auth/layout/layout.component.scss */
:host {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  position: relative;
  min-height: 100vh;
  min-width: 100vw;
  height: 100vh;
  background-color: #778899;
  color: #6c757d;
}
.logo {
  display: inline-block;
  padding: 0.375rem 1rem;
  cursor: pointer;
}
.logo img {
  height: 2.875rem;
}
.content-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
  width: 100vw;
}
/*# sourceMappingURL=layout.component.css.map */
